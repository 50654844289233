import Foto from './image/1684620138660.jpeg';
// import DTK from './image/denizcilikTahkim.webp';
// import Binance from './image/binance.webp';
// import Blog from './image/blogger.webp';
// import Solution from './image/solution.webp';
// import NPM from './image/npm.webp';
// import GL from './image/gl.webp';
// import MV from './image/mv.webp';
// import ART from './image/art.webp';
// import BLN from './image/bln.webp';
// import Blind from './image/blindlover.webp';
import Love from './image/love.jpg';
import Babe from './image/babe.jpeg';
// import Momey from './image/momey.webp';
// import FHTS from './image/fithouse.webp';

type ImagesType = {
  [key: string]: any;
};

const Images: ImagesType = {
  Foto,
  Love,
  Babe,
  // DTK,
  // Binance,
  // Blog,
  // Solution,
  // BLN,
  // NPM,
  // GL,
  // MV,
  // ART,
  // Blind,
  // Momey,
  // FHTS,
};

export default Images;
